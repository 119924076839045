<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>

      <ValidationObserver v-if="is_API_loaded" v-slot="{ invalid }">
        <main class="space-y-10">
          <div class="space-y-10">
            <section class="block">
              <div class="leftContent">
                <h3 class="h3">商店名稱和描述</h3>
                <p>管理商店名稱、商店描述，有助於商店在搜尋引擎上的排名。</p>
              </div>

              <div class="box">
                <div class="relative">
                  <h4 class="h4 mb-1">商店名稱 <span>*</span></h4>
                  <ValidationProvider name="商店名稱" rules="required|max:20" v-slot="{ errors }">
                    <vs-input v-model="data.sname"> </vs-input>

                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div class="relative">
                  <h4 class="h4 mb-1">商店描述 <span>*</span></h4>
                  <ValidationProvider name="商店描述" rules="required|max:200" mode="aggressive" v-slot="{ errors }">
                    <myTextarea
                      height="100px"
                      v-model="data.sdescs"
                      placeholder="輸入商店描述，有助於商店在搜尋引擎上的排名。"
                    ></myTextarea>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </section>
            <hr />
            <section class="block">
              <div class="leftContent">
                <h3 class="h3">公司資料</h3>
                <p>管理公司基本資料。</p>
                <p>若非公司，請填寫正確聯絡電話和工作/辦公地址。</p>
              </div>

              <div class="box">
                <!-- <h1 class="bigTitle">公司資料</h1> -->
                <div class="relative">
                  <h4 class="h4 mb-1">公司名稱</h4>
                  <ValidationProvider name="公司名稱" rules="max:20" v-slot="{ errors }">
                    <vs-input v-model="data.cname"> </vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div class="relative">
                  <h4 class="h4 mb-1">統一編號</h4>
                  <ValidationProvider name="統一編號" rules="max:20" v-slot="{ errors }">
                    <vs-input v-model="data.cvat"> </vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
                <div class="relative">
                  <h4 class="h4 mb-1">聯絡電話 <span>*</span></h4>
                  <ValidationProvider name="聯絡電話" rules="required|max:20" v-slot="{ errors }">
                    <vs-input v-model="data.tel"> </vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>

                <div class="grid gap-4 grid-cols-2">
                  <div class="relative w-full">
                    <h4 class="h4 mb-1">國家/地區 <span>*</span></h4>
                    <ValidationProvider name="國家/地區" rules="required" v-slot="{ errors }">
                      <vs-select class="w-full" v-model="data.shit" v-if="data.shit">
                        <vs-option v-for="item in shit" :key="item.value" :label="item.label" :value="item.value">{{
                          item.label
                        }}</vs-option>
                      </vs-select>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div class="relative w-full">
                    <h4 class="h4 mb-1">縣市 <span>*</span></h4>
                    <ValidationProvider name="縣市" rules="required|max:20" v-slot="{ errors }">
                      <!-- 縣市(若為台灣，選單) -->
                      <vs-select v-if="data.shit === '台灣'" @change="cityHandler" class="w-full" v-model="data.city">
                        <vs-option v-for="city in TW_districts" :key="city.name" :label="city.name" :value="city.name">{{
                          city.name
                        }}</vs-option>
                      </vs-select>

                      <!-- 縣市(非台灣，輸入框) -->
                      <vs-input v-else v-model="data.city"> </vs-input>

                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>

                  <div class="relative w-full">
                    <h4 class="h4 mb-1">鄉鎮市區 <span>*</span></h4>
                    <ValidationProvider name="鄉鎮市區" rules="required|max:20" v-slot="{ errors }">
                      <!-- 鄉鎮市區(若為台灣，選單) -->
                      <vs-select
                        :key="_district.districts.length"
                        v-if="data.shit === '台灣' && _district"
                        @change="districtHandler"
                        class="w-full"
                        v-model="data.area"
                      >
                        <vs-option
                          v-for="district in _district.districts"
                          :key="district.name"
                          :label="district.name"
                          :value="district.name"
                          >{{ district.name }}</vs-option
                        >
                      </vs-select>

                      <!-- 鄉鎮市區(非台灣，輸入框) -->
                      <vs-input v-else v-model="data.area"> </vs-input>

                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div class="relative w-full">
                    <h4 class="h4 mb-1">郵遞區號 <span>*</span></h4>
                    <ValidationProvider name="郵遞區號" rules="required|max:20" v-slot="{ errors }">
                      <vs-input v-model="data.zipcode"> </vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="relative">
                  <h4 class="h4 mb-1">地址 <span>*</span></h4>
                  <ValidationProvider name="地址" rules="required|max:100" v-slot="{ errors }">
                    <vs-input v-model="data.address"> </vs-input>
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </section>
            <hr />
            <section class="block">
              <div class="leftContent">
                <h3 class="h3">商店品牌標誌</h3>
                <p>品牌標誌會顯示在網站上，並於不同尺寸的裝置上呈現最佳效果。</p>
                <vs-alert color="success">
                  <template #title>調整大小</template>
                  ※ 可以於 <span class="font-medium">編輯佈景主題 > 頁首設定</span> 中調整。
                </vs-alert>
                <vs-alert color="danger">
                  <template #title>儲存後前台圖片沒有變更?</template>
                  ※ 為了提升載入速度，瀏覽器並不會主動載入新更換的圖片，你只需<span class="font-medium">清除瀏覽器快取</span
                  >並重新整理即可。
                </vs-alert>
              </div>

              <section class="box">
                <div class="grid grid-cols-3 w-full gap-2 sm:gap-4">
                  <div>
                    <h4 class="h4 mb-1">電腦版</h4>

                    <div class="upload aspect-ratio-1-1_global_style">
                      <img v-if="data.pclogo" :src="data.pclogo" class="uploadImg" />
                      <div v-else class="upload_icon">
                        <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                        <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                      </div>
                      <input type="file" @change="uploadImg1" accept="image/*" class="img_input" />
                    </div>
                  </div>
                  <div>
                    <h4 class="h4 mb-1">行動裝置版</h4>

                    <div class="upload aspect-ratio-1-1_global_style">
                      <img v-if="data.mblogo" :src="data.mblogo" class="uploadImg" />
                      <div v-else class="upload_icon">
                        <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                        <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                      </div>
                      <input type="file" @change="uploadImg2" accept="image/*" class="img_input" />
                    </div>
                  </div>
                  <div>
                    <h4 class="h4 mb-1">Favicon</h4>

                    <div class="upload aspect-ratio-1-1_global_style">
                      <img v-if="data.favicon" :src="data.favicon" class="uploadImg" />
                      <div v-else class="upload_icon">
                        <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                        <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                      </div>
                      <input type="file" @change="uploadImg3" accept="image/*" class="img_input" />
                    </div>
                  </div>
                </div>
              </section>
            </section>

            <!-- <section class="box">
            <h1 class="bigTitle">幣別<span > *</span></h1>
            <ValidationProvider tag="div" name="國家/地區" rules="required" v-slot="{ errors }">
              <vs-select class="w-full" v-model="currency">
                <vs-option label="台幣(TWD)" value="0"> 台幣(TWD) </vs-option>
                <vs-option label="美元(USD)" value="1"> 美元(USD) </vs-option>
                  <vs-option label="人民幣(CYN)" value="2"> 人民幣(CYN) </vs-option>
                  <vs-option label="港幣(HKD)" value="3"> 港幣(HKD) </vs-option>
                  <vs-option label="日幣(JPY)" value="4"> 日幣(JPY) </vs-option>
                  <vs-option label="澳幣(AUD)" value="5"> 澳幣(AUD) </vs-option>
                  <vs-option label="歐元(EUR)" value="6"> 歐元(EUR) </vs-option>
                  <vs-option label="馬來西亞(MYR)" value="7"> 馬來西亞(MYR) </vs-option>
              </vs-select>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </section> -->
            <!-- <section class="box">
            <h1 class="bigTitle">商店類型<span > *</span></h1>
            <ValidationProvider tag="div" name="商店類型" rules="required" v-slot="{ errors }">
              <vs-select class="w-full" v-model="stype">
                <vs-option label="形象官網" :value="0">形象官網</vs-option>
                <vs-option label="一頁式官網" :value="1">一頁式形象官網</vs-option>
                <vs-option label="官網電商" :value="3">官網電商</vs-option>
              </vs-select>
              <errorDiv>{{ errors[0] }}</errorDiv>
            </ValidationProvider>
          </section> -->

            <!-- <section class="box">
            <h1 class="bigTitle">指定業務</h1>
            <div>
              <h4 class="h4 mb-1">業務ID</h4>
              <vs-input v-model="sid"> </vs-input>
            </div>
          </section> -->
          </div>

          <hr />

          <div class="flex justify-end">
            <vs-button @click="savePage(invalid)" size="large" :disabled="invalid" ref="savePage"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </ValidationObserver>

      <!-- 骨架屏 -->
      <div class="space-y-5" v-else>
        <section v-for="(item, index) in 2" :key="index" class="block">
          <div class="leftContent">
            <skeleton class="mt-4" width="100px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
          </div>
          <div class="box">
            <div class="flex items-center justify-between">
              <skeleton width="100px" />
              <skeleton width="100px" />
            </div>
            <div class="flex items-start flex-col space-y-2">
              <skeleton v-for="(item, index) in 3" :key="index" width="200px" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TW_districts from '@/assets/json/TW_districts.json' // 台灣縣市 郵遞區號 列表
export default {
  name: 'general',
  data() {
    return {
      data: {},
      shit: [
        {
          label: '台灣',
          value: '台灣',
        },
        {
          label: '美國',
          value: '美國',
        },
        {
          label: '中國大陸',
          value: '中國大陸',
        },
        {
          label: '香港',
          value: '香港',
        },
        {
          label: '日本',
          value: '日本',
        },
        {
          label: '澳洲',
          value: '澳洲',
        },
        {
          label: '歐洲',
          value: '歐洲',
        },
        {
          label: '馬來西亞',
          value: '馬來西亞',
        },
        {
          label: '其他地區',
          value: '其他地區',
        },
      ],
      TW_districts: TW_districts, // 台灣縣市 郵遞區號 列表
      is_API_loaded: false,
    }
  },
  computed: {
    // 篩選出鄉鎮市區
    _district() {
      let city = this.data.city
      return this.TW_districts.find((item) => item.name === city)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    // 4.2.1.1 商店資料-讀取
    loadData() {
      this.$axios({
        url: 'front/set/basic/getStoreData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        console.log(res, '4.2.1.1 商店資料-讀取')
        if (res.data.Success) {
          let data = res.data.Data

          data.pclogo = logoValidate(data.pclogo)
          data.mblogo = logoValidate(data.mblogo)
          data.favicon = logoValidate(data.favicon)

          // 判斷空值
          function logoValidate(link) {
            if (link === 'data:;base64,\r\n') return ''
            return link
          }

          this.data = data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.2.1.2 商店資料-修改
    savePage(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.savePage,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/basic/uStoreData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sname: this.data.sname,
          sdescs: this.data.sdescs,
          pclogo: this.data.pclogo,
          mblogo: this.data.mblogo,
          favicon: this.data.favicon,
          cname: this.data.cname,
          cvat: this.data.cvat,
          tel: this.data.tel,
          shit: this.data.shit,
          city: this.data.city,
          area: this.data.area,
          zipcode: this.data.zipcode,
          address: this.data.address,
          currency: this.data.currency,
          sid: this.sid,
          stype: this.stype,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.getWebData()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 1.3.1 讀取網站參數
    getWebData() {
      this.$axios({
        url: 'front/overview/getWebParam.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 儲存網站參數
          this.$store.commit('getWebData/UPDATE_COOKIE', res.data.Data)
        }
      })
    },
    // 選擇縣市時，改變鄉鎮市區和郵遞區號
    cityHandler(e) {
      console.log(e)
      const city = e
      let cityObj = this.TW_districts.find((item) => item.name === city)
      this.data.area = cityObj.districts[0].name
      this.data.zipcode = cityObj.districts[0].zip
    },
    // 選擇鄉鎮市區時，改變郵遞區號
    districtHandler(e) {
      const city = this.data.city
      let obj = this.TW_districts.find((item) => item.name === city).districts.find((district) => district.name === e)
      this.data.zipcode = obj.zip
    },
    uploadImg1(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.data.pclogo = e.target.result
      }
      e.target.value = ''
    },
    uploadImg2(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.data.mblogo = e.target.result
      }
      e.target.value = ''
    },
    uploadImg3(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.data.favicon = e.target.result
      }
      e.target.value = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.block {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-5 sm:space-y-0;
}

.leftContent {
  @apply w-full sm:w-1/3 space-y-5 flex-shrink-0 sm:mt-4;
}

.box {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-5;
}

.title {
  @apply text-sm text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

.vs-select-content {
  max-width: 100% !important;
}

.uploadImg {
  @apply absolute top-1/2 transform -translate-y-1/2 object-contain;
  width: 100%;
  height: 100%;
}
.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
}

.upload_icon {
  @apply flex flex-col items-center absolute top-1/2 transform -translate-y-1/2;
}

.img_input {
  @apply border-2 absolute z-10 h-full w-full cursor-pointer opacity-0 top-1/2 transform -translate-y-1/2;
}
</style>
